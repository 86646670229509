import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import AdminSidebar from '../components/AdminSidebar';
import Header from "../components/header/Header";

const AdminLayout: FC = () => {
    return (
        <div className='admin-layout'>
            <Header/>

            <div className="container-fluid p-0 h-100">
                <div className="row no-gutters h-100">
                    <div className="col-12">
                        <div className="content">
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};
export default AdminLayout;
