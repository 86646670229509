import React, { FC } from "react";
import Button from "../form/Button";
import {useNavigate} from "react-router-dom";
import {useAppDispatch} from "../../redux/store";
import Cookies from "js-cookie";
import {resetUser} from "../../redux/slices/userSlice";
import Logo from "../../img/logo.svg";
import Reload from "../../img/reload.svg";
import Setting from "../../img/setting.svg";
import Exit from "../../img/exit.svg";
import Profile from "../../img/profile.png";

interface HeaderProps {
}
const Header: FC<HeaderProps> = ({ }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const logout = () => {
        Cookies.remove('token');
        dispatch(resetUser());
        navigate('/');
    }

    function onClickReload() {
        window.location.reload();
    }

    function onClickMap() {}

    return (
        <div className="header">
            <div className="header__left">
                <div className={'header__logo'} onClick={() => navigate('/admin/history')}>
                    <img src={Logo} alt=""/>

                    <p>Partner API</p>
                </div>
            </div>

            <div className="header__right">
                <div className={'header__other'}>
                    <Button
                        className={'btn btn-icon'}
                        text={(
                            <>
                                <img src={Reload} alt="reload"/>
                            </>
                        )}
                        onClick={onClickReload}
                    />

                    {/*<Button*/}
                    {/*    className={'btn btn-icon'}*/}
                    {/*    text={(*/}
                    {/*        <>*/}
                    {/*            <img src={Setting} alt="setting"/>*/}
                    {/*        </>*/}
                    {/*    )}*/}
                    {/*    onClick={onClickMap}*/}
                    {/*/>*/}

                    <Button
                        className={'btn btn-icon'}
                        text={(
                            <>
                                <img src={Exit} alt="exit"/>
                            </>
                        )}
                        onClick={logout}
                    />
                </div>

                {/*<div className={'header__profile'}>*/}
                {/*    <div className="header__profile-logo">*/}
                {/*        <img src={Profile} alt=""/>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </div>
    );
}

export default Header;
