import React, {FC, ReactNode} from "react";
import Spinner from '../Spinner';

interface ButtonProps {
    loading?: boolean,
    leftIcon?: ReactNode | undefined,
    rightIcon?: ReactNode | undefined,
    text: string | ReactNode,
    onClick: () => void,
    className?: string,
    type?: 'submit' | 'reset' | 'button' | undefined;
}
const Button: FC<ButtonProps> = (
    {
        loading,
        leftIcon,
        rightIcon,
        text,
        onClick,
        className,
        type = 'button'
    }
) => {
    function onClickBtn() {
        onClick()
    }

    return (
        <button
            type={type}
            onClick={onClickBtn}
            className={className}
            style={{ gap: (leftIcon || rightIcon) ? '10px' : '0' }}
        >
            { leftIcon && (
                <span className="header__left">
                    { leftIcon }
                </span>
            )}

            { loading ? (
                <div className="vertical-center" style={{ position: 'relative' }}>
                    <Spinner />
                </div>
            ) : text }

            { rightIcon && (
                <span className="header__right">
                    { rightIcon }
                </span>
            )}
        </button>
    );
}

export default Button;
