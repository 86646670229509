import { ChangeEvent, FC, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { withDebounce } from '../functions';
import { setDefaultLocale } from "react-datepicker";
import ru from 'date-fns/locale/ru';
import { selectOption } from "../types/users";

setDefaultLocale('ru');

type DateInterface = Date | null;

import CalendarIcon from '../img/calendar.svg';
import Report from '../img/icons/report.svg';
import {useAppDispatch} from "../redux/store";
import {getFileDownload} from "../redux/actions/orderActions";
import moment from "moment/moment";

interface OrderFiltersProps {
    users: selectOption[] | []
    onFilterChange: (param: string, value: any) => void
    onDateRange: (name: string, value: Date | null) => void
}
const OrderFilters: FC<OrderFiltersProps> = ({ users, onFilterChange, onDateRange }) => {
    const [startDate, setStartDate] = useState<DateInterface>(null);
    const [endDate, setEndDate] = useState<DateInterface>(null);
    const [searchValue, setSearchValue] = useState<string>('');

    const dispatch = useAppDispatch();

    const statuses = [
        { value: null, label: 'Все полисы' },
        { value: -1, label: 'Отменено' },
        { value: 0, label: 'Не оплачено' },
        { value: 3, label: 'Оплачено' },
    ];

    const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        const search = event.target.value;

        setSearchValue(search);

        if (search.length >= 3 || search.length === 0) {
            withDebounce(() => {
                onFilterChange("search", search ? search : null);
            });
        }
    };

    function onClickDownloadFile() {
        dispatch(getFileDownload(
            {
                from: startDate ? moment(startDate).format('YYYY-MM-DD') : null,
                to: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
                search: searchValue ? searchValue : null
            }));
    }

    return (
        <div className="order-filters small-gutters">
            <div className="row mb-3">
                <div className="col-4">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon1">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9 2C5.14585 2 2 5.14585 2 9C2 12.8541 5.14585 16 9 16C10.748 16 12.345 15.348 13.5742 14.2812L14 14.707V16L20 22L22 20L16 14H14.707L14.2812 13.5742C15.348 12.345 16 10.748 16 9C16 5.14585 12.8541 2 9 2ZM9 4C11.7733 4 14 6.22673 14 9C14 11.7733 11.7733 14 9 14C6.22673 14 4 11.7733 4 9C4 6.22673 6.22673 4 9 4Z" fill="black" />
                                </svg>
                            </span>
                        </div>

                        <input
                            value={searchValue}
                            onChange={onSearchChange}
                            type="text"
                            className="form-control"
                            placeholder="Поиск"
                        />
                    </div>
                </div>
                <div className="col-lg-8 col-md-12 mt-3 mt-md-0 mt-sm-3 mt-lg-0">
                    <div className={'d-flex'}>
                        {/*<FilterSelect options={statuses} onChange={(val) => {*/}
                        {/*    onFilterChange('status', val.value);*/}
                        {/*}} />*/}
                        <div className="custom-calendar">
                            {/*<div className="delimetr"></div>*/}

                            <div className="picker-wrapper">
                                <DatePicker
                                    locale={ru}
                                    selected={startDate}
                                    dateFormat="dd.MM.yyyy"
                                    onChange={(date) => {
                                        onDateRange('from', date);
                                        setStartDate(date);
                                    }}
                                    selectsStart
                                    showYearDropdown
                                    showMonthDropdown
                                    startDate={startDate}
                                    endDate={endDate}
                                    placeholderText={'Дата отправления'}
                                />

                                <div className="icon-wrapper">
                                    <img src={CalendarIcon} alt="calendaricon" />
                                </div>
                            </div>

                            <div className="delimetr"></div>

                            <div className="picker-wrapper">
                                <DatePicker
                                    locale={ru}
                                    selected={endDate}
                                    dateFormat="dd.MM.yyyy"
                                    onChange={(date) => {
                                        onDateRange('to', date);
                                        setEndDate(date);
                                    }}
                                    selectsEnd
                                    showYearDropdown
                                    showMonthDropdown
                                    startDate={startDate}
                                    endDate={endDate}
                                    minDate={startDate}
                                    placeholderText={'Дата прибытия'}
                                />

                                <div className="icon-wrapper">
                                    <img src={CalendarIcon} alt="calendaricon" />
                                </div>
                            </div>
                        </div>

                        <button
                            className="btn btn-black ml-lg-auto "
                            type={'button'}
                            onClick={onClickDownloadFile}
                        >
                            <img src={Report} alt="" />

                            Получить отчёт
                        </button>
                    </div>
                </div>
            </div>
            {/*<div className="number_link">*/}
            {/*    <div className="link_innerp">*/}
            {/*        <h5>25.07.2023</h5>*/}
            {/*    </div>*/}
            {/*    <div className="link_ups">*/}
            {/*        <p>5 записей</p>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
}

export default OrderFilters;
