import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import SignInLayout from '../layouts/SignInLayout';
import AdminLayout from '../layouts/AdminLayout';
import Login from "../containers/login";
import PolicyPage from "../containers/polices";
import NotFound from "../components/NotFound";
import ProtectedRoute from "../components/ProtectedRoute";
import CardPage from "../containers/card";

const RoutesComponent = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    element={<SignInLayout/>}
                >
                    <Route path="/" element={<Login/>}/>
                </Route>

                <Route
                    element={<AdminLayout/>}
                >
                    <Route path="/admin" element={<ProtectedRoute/>}>
                        <Route index element={<Navigate to={'/admin/history'} replace/>}/>

                        <Route path="history" element={<PolicyPage/>}/>

                        <Route path="card/:id" element={<CardPage/>}/>
                    </Route>
                </Route>

                <Route
                    path={'*'}
                    element={<NotFound/>}
                />
            </Routes>
        </BrowserRouter>
    );
};
export default RoutesComponent;
