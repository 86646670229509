import { FC, useState } from "react";
import {formatDate, formatDateWithTime} from "../functions";
import {useNavigate} from "react-router-dom";

interface AccordionItemProps {
    item: any
    onStatusChange: (status: number) => void
}
const AccordionItem: FC<AccordionItemProps> = ({ item, onStatusChange }) => {
    const navigate = useNavigate();

    const [active, setActive] = useState(false);

    const onToggle = () => {
        navigate(`/admin/card/${item.id}`)
    }

    return (
        <div className="card">
            <div className="card-header" id={`heading-${item.ttn_id}`}>
                <div
                    className="collapsed"
                    data-toggle="collapse"
                    data-target={`#collapse-${item.ttn_id}`}
                    aria-expanded="true"
                    aria-controls={`collapse-${item.ttn_id}`}
                    onClick={onToggle}
                >
                    <div className="row">
                        <div className="col id">
                            <p>№ накладной</p>

                            {item.ttn_id}
                        </div>
                        <div className="col">
                            <p>Наименование перевозчика</p>

                            {item.supplier_name}
                        </div>
                        <div className="col">
                            <p>Адрес прибытия</p>

                            {item.destination_address}
                        </div>
                        <div className="col">
                            <p>Гос. номер</p>

                            {item.vehicle_number_plate}
                        </div>
                        <div className="col date">
                            <p>Дата создания</p>

                            {item.carriage_created_at ? formatDateWithTime(item.carriage_created_at) : null}
                        </div>
                        <div className="col date">
                            <p>Дата отправления</p>

                            {item.departure_at ? formatDateWithTime(item.departure_at) : null}
                        </div>
                    </div>
                </div>
            </div>

            {/*<div id={`collapse-${item.ttn_id}`} className="collapse" aria-labelledby={`heading-${item.ttn_id}`}>*/}
            {/*    <div className="card-body">*/}
            {/*        <div className="divider"></div>*/}
            {/*        <div className="row">*/}
            {/*            <div className="col-3">*/}
            {/*                <div className="item">*/}
            {/*                    <div className="sub-heading">Тип страхования</div>*/}
            {/*                    {item.form && item.form.tariff === null || item.form.tariff === '1' ?*/}
            {/*                        'Страхование от потери дохода' :*/}
            {/*                        'Страхование от несчастных случаев и потери дохода'*/}
            {/*                    }*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="col-2 ml-auto">*/}
            {/*                <div className="item">*/}
            {/*                    <div className="sub-heading">Стоимость</div>*/}
            {/*                    {item.amount ? `${formatPrice(item.amount)}₽` : null}*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="col-2">*/}
            {/*                <div className="item">*/}
            {/*                    <div className="sub-heading">Сумма страхования</div>*/}
            {/*                    {item.limit_amount ? `${formatPrice(item.limit_amount)}₽` : null}*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="col-2">*/}
            {/*                <div className="item">*/}
            {/*                    <div className="sub-heading">Срок страхования</div>*/}
            {/*                    {item.form && item.form.years ? `${item.form.years} ${declOfNum(item.form.years, textForms)}` : null}*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="row">*/}
            {/*            <div className="col-5">*/}
            {/*                <div className="item">*/}
            {/*                    <div className="sub-heading">Страхователь</div>*/}
            {/*                    <div className="heading">{item.insurer ? item.insurer : null}</div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="col-2 ml-auto">*/}
            {/*                <div className="item">*/}
            {/*                    <div className="sub-heading">Паспорт</div>*/}
            {/*                    <div className="heading">{item.form && item.form.passport_number ? item.form.passport_number : null}</div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="col-2">*/}
            {/*                <div className="item">*/}
            {/*                    <div className="sub-heading">Номер телефона</div>*/}
            {/*                    <div className="heading">{item.phone ? item.phone : null}</div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="row">*/}
            {/*            <div className="col-5">*/}
            {/*                <div className="item">*/}
            {/*                    <div className="sub-heading">E-mail</div>*/}
            {/*                    <div className="heading">{item.email ? item.email : null}</div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="divider"></div>*/}
            {/*        <div className="row">*/}
            {/*            <div className="col-3">*/}
            {/*                <div className="item">*/}
            {/*                    <div className="sub-heading">Кредитный договор</div>*/}
            {/*                    <div className="heading">{item.form && item.form.credit_number ? `№${item.form.credit_number}` : null}</div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="col-4">*/}
            {/*                <div className="item">*/}
            {/*                    <div className="sub-heading">Адрес</div>*/}
            {/*                    <div className="heading">{item.address ? item.address : null}</div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="col-4">*/}
            {/*                <div className="item">*/}
            {/*                    <div className="sub-heading">Корпус</div>*/}
            {/*                    <div className="heading">{item.form && item.form.building ? item.form.building : null}</div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="divider"></div>*/}
            {/*        <div className="row">*/}
            {/*            <div className="col-5">*/}
            {/*                <div className="item">*/}
            {/*                    <div className="sub-heading big">Полис</div>*/}
            {/*                    {item.policy_url && (*/}
            {/*                        <a href={item.policy_url}>{item.policy_url}</a>*/}
            {/*                    )}*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="col-3">*/}
            {/*                <div className="item">*/}
            {/*                    <div className="sub-heading big">Счёт на оплату</div>*/}
            {/*                    {item.invoice_url && (*/}
            {/*                        <a href={item.invoice_url}>{item.invoice_url}</a>*/}
            {/*                    )}*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="row">*/}
            {/*            <div className="col-12">*/}
            {/*                <div className="item">*/}
            {/*                    <div className="sub-heading big">Статус оплаты</div>*/}
            {/*                    <div className="d-flex">*/}
            {/*                        <div className={cn('btn', { 'btn-primary': item.status === 3, 'btn-gray-transparent': item.status !== 3 })} onClick={() => {*/}
            {/*                            onStatusChange(3);*/}
            {/*                        }}>*/}
            {/*                            Оплачен*/}
            {/*                        </div>*/}
            {/*                        <div className={cn('btn', { 'btn-primary': item.status === 0, 'btn-gray-transparent': item.status !== 0 })} onClick={() => {*/}
            {/*                            onStatusChange(0);*/}
            {/*                        }}>*/}
            {/*                            Не оплачен*/}
            {/*                        </div>*/}
            {/*                        <div className={cn('btn', { 'btn-primary': item.status === -1, 'btn-gray-transparent': item.status !== -1 })} onClick={() => {*/}
            {/*                            onStatusChange(-1);*/}
            {/*                        }}>*/}
            {/*                            Отменён*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
}

export default AccordionItem;
