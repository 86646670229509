import { FC, useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import {useNavigate, useParams} from "react-router-dom";
import {getOrder} from "../../redux/actions/orderActions";
import {formatDate, formatDateWithTime, formatPrice} from "../../functions";

export interface ItemCardData {
    title: string
    text: string
    className?: string
    textRight?: string
}

const CardPage: FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { id } = useParams();

    const [listRight, setListRight]: any[] = useState([]);
    const [listLeft, setListLeft]: any[] = useState([]);

    useEffect(() => {
        if (id) dispatch(getOrder(id)).then(i => {
            console.log(i.payload, 9)

            if (!i.payload) return;

            getAddLeftList(i.payload);
            getAddRightList(i.payload);
        })
    }, [id])

    function onClickBack() {
        navigate('/admin/history')
    }

    function getAddLeftList(data: any) {
        const listLeftDefault = [
            {
                title: 'Номер транспортной накладной',
                text: data.ttn_id,
            },
            {
                title: 'Наименование перевозчика',
                text: data.supplier_name
            },
            {
                title: 'Адрес прибытия',
                text: data.destination_address
            },
            {
                title: 'Марка и модель машины',
                text: data.car_model
            },
            {
                title: 'Гос. номер',
                text: data.vehicle_number_plate
            },
            {
                title: 'Водитель',
                text: data.driver_name
            },
            {
                title: 'ИНН перевозчика',
                text: data.supplier_inn
            },
        ]

        setListLeft(listLeftDefault)
    }

    function getAddRightList(data: any) {
        const listRightDefault = [
            {
                title: 'Дата создания перевозки',
                text: formatDateWithTime(data.carriage_created_at),
            },
            {
                title: 'Адрес отправления',
                text: data.address,
                className: 'date-start'
            },
            {
                title: 'Адрес прибытия',
                text: data.destination_address,
                className: 'date-end'
            },
            {
                title: 'Дата и время отправления',
                text: data.departure_at ? formatDateWithTime(data.departure_at) : 'Неизвестно',
                // textRight: 'UTC +3'
            },
            {
                title: 'Дата и время прибытия',
                text: data.arrival_at ? formatDateWithTime(data.arrival_at) : 'Неизвестно',
                // textRight: 'UTC +3'
            },
            {
                title: 'Стоимость перевозимого имущества',
                text: formatPrice(data.price) + ' ₽'
            },
        ]

        setListRight(listRightDefault)
    }

    return (
        <>
            <div className={'card-info'}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-info-header">
                                <h4>ID {id}</h4>

                                <button
                                    type={'button'}
                                    className={'btn btn-primary'}
                                    onClick={onClickBack}
                                >
                                    Закрыть
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="card-info-content">
                        <div className="row">
                            <div className="col-md-6 col-12">
                                <h5>Информация о перевозчике</h5>

                                <div className="card-info-content-items">
                                    {listLeft.map((i: ItemCardData, idx: number) => {
                                        if (i.text) {
                                            return (
                                                <div
                                                    className={`card-info-content-item ${!idx && 'first'} ${i.className}`}
                                                    key={`card-info-content-item-${idx}`}
                                                >
                                                    <div className={'w-100'}>
                                                        <p className={'title'}>{i.title}</p>

                                                        <div className={'d-flex align-items-center'}>
                                                            <p className={'text'}>{i.text}</p>

                                                            {i.textRight && (
                                                                <p className={'text textRight'}>{i.textRight}</p>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }

                                        return null;
                                    })}
                                </div>
                            </div>

                            <div className="col-6">
                                <h5>Информация о перевозчике</h5>

                                <div className="card-info-content-items">
                                    {listRight.map((i: ItemCardData, idx: number) => {
                                        if (i.text) {
                                            return (
                                                <div
                                                    className={`card-info-content-item ${!idx && 'first'} ${i.className}`}
                                                    key={`card-info-content-item-${idx}`}
                                                >
                                                    <div className={'w-100'}>
                                                        <p className={'title'}>{i.title}</p>

                                                        <div className={'d-flex align-items-center'}>
                                                            <p className={'text'}>{i.text}</p>

                                                            {i.textRight && (
                                                                <p className={'text textRight'}>{i.textRight}</p>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }

                                        return null;
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CardPage;
