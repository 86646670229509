import { FC, useState, useEffect } from "react";
import moment from "moment";
import Accordion from "../../components/Accordion";
import { getOrders } from "../../redux/actions/orderActions";
import OrderFilters from '../../components/OrderFilters';
import OrdersPagination from "../../components/OrdersPagination";
import { resetStatus } from '../../redux/slices/orderSlice';
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { PolicyFilterProps } from "../../types/polices";

const PolicyPage: FC = () => {
    const dispatch = useAppDispatch();
    const orders = useAppSelector((state) => state.orders);
    const users = useAppSelector((state) => state.users);

    const [filterProps, setFilterProps] = useState<PolicyFilterProps>({
        paginated: true,
        page: 1
    });

    const [ sortList, setSortList ] = useState<any[]>([]);

    useEffect(() => {
        dispatch(getOrders(filterProps));
    }, [filterProps, orders.changeStatus.success]);

    useEffect(() => {
        if (orders.data) {
            const sortListUpdate = [...orders?.data?.data] || [];
            // sortListUpdate.sort((a, b) => b.id - a.id);

            setSortList(sortListUpdate)
        }
    }, [orders]);

    useEffect(() => {
        if (orders.changeStatus.success) {
            dispatch(resetStatus());
        }
    }, [orders.changeStatus]);

    const onFilterChange = (prop: string, value: any) => {
        setFilterProps({
            ...filterProps,
            [prop]: value
        })
    };

    const onDateRange = (name: string, value: Date | null) => {
        setFilterProps({
            ...filterProps,
            page: undefined,

            [name]: value ? moment(value).format('YYYY-MM-DD') : null,
        })
    };

    const onTopFiltersChange = (prop: string, value: any) => {
        setFilterProps({
            ...filterProps,
            page: undefined,
            [prop]: value
        })
    };

    return (
        <>
            <div className="information list-wrapper">
                <div className="row">
                    <div className="col-12">
                        <OrderFilters
                            users={users.data}
                            onFilterChange={onTopFiltersChange}
                            onDateRange={onDateRange}
                        />

                        <Accordion
                            loading={orders.loading}
                            list={sortList}
                        />

                        {orders.data && orders.data.total > 20 && (
                            <OrdersPagination
                                last_page={orders.data.last_page}
                                onFilterChange={onFilterChange}
                                initialPage={filterProps.page || 1}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default PolicyPage;
