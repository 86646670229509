import Worker from '../../img/banner.png';
import SignInForm from '../../components/SignInForm';
import SignInFooter from '../../components/SignInFooter';
const Login = () => {
    return (
        <div className="login-page">
            <div className="sign-bg" style={{ backgroundImage: `url(${Worker})` }}>
                <div className="container-fluid d-flex align-items-center justify-content-center">
                    <div className="row w-100 justify-content-center">
                        <div className="col-12">
                            <SignInForm />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
