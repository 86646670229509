import React, {useEffect, FC, useState} from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { requiredPattern, emailPattern } from "../functions";
import { login } from '../redux/actions/userActions';
import Cookies from "js-cookie";
import cn from 'classnames';
import Spinner from "./Spinner";
import { useAppDispatch } from "../redux/store";
import { loginData } from "../types/user";
import { useAppSelector } from "../redux/store";
import { loginFormDataTypes } from "../types/login";
import Logo from "../img/logo.svg";
import Password from "../img/password.svg"

const SignInForm: FC = () => {
    const [valueEmail, setValueEmail] = useState('');
    const [valuePassword, setValuePassword] = useState('');
    const [isValueForm, setIsValueForm] = useState(false);
    const [isShowPassword, setIsShowPassword] = useState(false);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const currentUser = useAppSelector((state) => state.currentUser);
    const { register, handleSubmit, formState: { errors } } = useForm<loginFormDataTypes>({});

    useEffect(() => {
        if (currentUser.success || Cookies.get('token')) {
            navigate('/admin');
        }
    }, [currentUser.success]);

    useEffect(() => {
        if (emailPattern.value.test(valueEmail) && valuePassword) {
            setIsValueForm(true);
        } else {
            setIsValueForm(false);
        }
    }, [valueEmail, valuePassword])

    const onSubmit = (data: loginData) => {
        dispatch(login(data));
    };

    return (
        <div className="sign-in-form">
            <img src={Logo} alt="" className={'sign-in-form-logo'}/>
            <h4>Авторизация</h4>
            <form onSubmit={handleSubmit(onSubmit)} className={'w-100'}>
                <div className="form-group">
                    <label>E-mail</label>
                    <input
                        placeholder="Введите e-mail"
                        className="form-control email-form-control"
                        {...register("email", {
                            required: requiredPattern, pattern: emailPattern
                        })}
                        value={valueEmail}
                        onChange={(e) => setValueEmail(e.target.value)}
                    />
                    {errors.email && <span className="error-message">{errors.email.message}</span>}
                </div>

                <div className="form-group">
                    <label>Пароль</label>

                    <div className="form-block">
                        <input
                            placeholder="Введите пароль"
                            className="form-control"
                            type={isShowPassword ? 'text' : 'password'}
                            {...register("password", {
                                required: requiredPattern
                            })}
                            value={valuePassword}
                            onChange={(e) => setValuePassword(e.target.value)}
                        />

                        <button
                            type={'button'}
                            className={'btn-password'}
                            onClick={() => setIsShowPassword(!isShowPassword)}
                        >
                            <img src={Password} alt=""/>
                        </button>
                    </div>

                    {errors.password && <span className="error-message">{errors.password.message}</span>}
                </div>

                <button
                    disabled={currentUser.loading || !isValueForm}
                    type="submit"
                    className={cn('btn btn-primary', { 'loading': true })}
                >
                    {currentUser.loading ? (
                        <Spinner />
                    ) : 'Войти'}
                </button>
            </form>
        </div>
    );
}

export default SignInForm;
