import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosAuth } from '../../axios-instances';
import { failureNotify, successNotify } from "../../notifications";
import { updateStatusObject } from "../../types/orders";
export const getOrders = createAsyncThunk(
    "orders/all",
    async (params: any) => {
        const response = await axiosAuth.get('dashboard/carriage/list', {
            params
        });
        return response.data.data;
    }
);

export const changeStatus = createAsyncThunk(
    "orders/changeStatus",
    async (params: updateStatusObject, { rejectWithValue }) => {
        const { order_id, ...fields } = params;
        try {
            const response = await axiosAuth.patch(`orders/${order_id}`, fields);
            successNotify(response.data.data);
            return response.data.data;
        } catch (error: any) {
            if (error.response.data && error.response.data.errors) {
                failureNotify(error.response.data.errors);
                return rejectWithValue(error.response.data.errors);
            }
            return rejectWithValue(error);
        }
    }
);

export const downloadFile = (url: string, filename: string) => {
    const downloadLink = document.createElement('a')
    downloadLink.href = url
    downloadLink.download = filename
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
}

export const getFileDownload = createAsyncThunk(
    "getFile",
    async (props: {
        search: string | null,
        to: string | null,
        from: string | null
    }) => {

        try {
            const response = await axiosAuth.get('dashboard/carriage/export', {
                responseType: 'blob',
                params: {
                    search: props.search,
                    to: props.to,
                    from: props.from
                }
            })

            const data = response?.data
            const urlCreator = window.URL || window.webkitURL
            const fileUrl = urlCreator.createObjectURL(data)
            const filename = 'Отчёт'.replace('attachment; filename=', '')
            downloadFile(fileUrl, filename)
            successNotify('Отчёт выгружен')
        } catch (error: any) {
            if (error.response.data && error.response.data.errors) {
                failureNotify(error.response.data.errors)
            }
        }
    }
);


export const getOrder = createAsyncThunk(
    "order",
    async (order_id: string) => {
        const response = await axiosAuth.get(`dashboard/carriage/single/${order_id}`, {});
        return response.data.data;
    }
);

